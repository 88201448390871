@import "./variables.scss";

* {
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro";
}
html,
body {
  height: 100%;
  background: #f7f7f7;
  height: 100%;
}
body {
  height: 100vh;
}

#root {
  height: 100%;
}
.App {
  font-family: sans-serif;
  height: 100%;
}

#top-nav {
  height: 4em;
  // border-bottom:1px solid $shadow-color;
  position: relative;
  box-shadow: 0px 5px 5px darken($shadow-color, 5%);
  box-sizing: border-box;
  padding-top: 0.5em;
  padding-left: 1.5em;
  padding-bottom: 0;
  // padding-bottom: .5em;
  display: flex;
  z-index: 5;
  background: white;
  .left {
    height: 100%;
    display: flex;
    align-items: center;
    .logo {
      // display:none;
      width: 9em;
      display: flex;
      align-items: center;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .right {
    display: flex;
    margin-left: auto;
    padding: 0.5em 0;
    padding-right: 1.5em;
    #btn-logout {
      background: none;
      border: none;
      font-size: 1.5rem;
      color: $third-text-color;
      cursor: pointer;
    }
    #user-info {
      display: flex;
      // flex-direction: column;
      margin-right: 1em;
      .profile-info {
        display: flex;
        flex-direction: column;
        margin-right: 1em;
        .username {
          font-size: 1rem;
          color: $third-text-color;
        }
        .company-name {
          font-size: 0.85rem;
          color: $third-text-color;
        }
      }
      .profile-picture {
        display: flex;
        align-items: center;
        img {
          width: 2.5em;
        }
      }
    }
  }
}

main {
  display: flex;
  height: 100%;
  z-index: 4;
  position: relative;
  #side-nav {
    box-shadow: 0px 4px 5px $shadow-color;
    // border-right:1px solid $shadow-color;
    flex: 1;
    min-width: 4em;
    max-width: 4em;
    height: 100%;
    // z-index: 10;
    background: white;
    ul {
      li {
        display: block;
        padding: 1em;
        text-align: center;
        &.burger {
          color: $third-text-color;
        }
        &.chartmenu {
          background: $shadow-color;
          span {
            display:block;
            padding:.3em;
            background: white;
            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  .page {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2em;
    height: 100%;
    background: $secondary-background;
    .page-header {
      // padding-left: 2em;
      // box-sizing: border-box;
      margin-bottom: 2em;
      display: flex;
      h1 {
        flex: 1;
        display: inline;
        color: $third-text-color;
        font-weight: 600;
      }
      .filterperiod-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        position: relative;
      }
      // padding-left:3em;
    }
  }

  #insights {
    // border:1px solid green;
    background: $secondary-background;
    // padding: 2em;
    flex: 7;
    display: flex;
    flex-wrap: wrap;
    .card {
      flex: 1;
      // min-height:25px;
    }
    #chart {
      flex: 2;
    }
  }
}

.card {
  box-shadow: 0px 1px 3px $shadow-color;
  background: $primary-background;
  margin-right: 2em;
  margin-bottom: 2em;
  border-radius: 2px;
  .card-title {
    padding: 1em;
    font-size: 1.2rem;
    color: $secondary-text-color;
  }
  .card-body {
    padding: 1em;
  }
}

.list-products {
  list-style: none;

  li.product-item {
    display: flex;
    border: 0.5px solid #c5c5c59c;
    margin-bottom: 0.5em;
    &.first {
      background: #ffe7bd;
      .product-thumbnail {
        flex: 1.5;
      }
    }
    .product-thumbnail {
      flex: 1;
      img {
        // max-width:10px;
        width: 100%;
      }
    }
    .product-infobox {
      flex: 1;
      margin: 8px;
      color: $secondary-text-color;
      h4 {
        color: $primary-text-color;
        margin-bottom: 0.5em;
        font-weight: 600;
      }
      .extra-info {
        font-size: 0.8rem;
        display: flex;
        span {
          flex: 1;
          &:first-child {
            margin-right: 0.2em;
          }
        }
      }
    }
  }
}

.list-product-card {
  max-width: 250px;
  // display:none;
  &:last-child {
    margin-right: 0;
  }
}

.filter-period {
  // max-height: 1.25em;
  background: white;

  color: $secondary-text-color;
  border-radius: 2px;
  box-shadow: 0px 2px 3px $shadow-color;
  display: inline-flex;
  align-self: flex-end;
  // align-items: center;
  width: auto;

  i,
  span,
  svg {
    margin-right: 1em;
    &:last-child {
      margin: 0;
    }
  }

  .filter-period-unexpanded {
    padding: 1em;
    height: 100%;
    cursor:pointer;
    // padding:5em;
    // display:none;
  }
  .filter-period-expanded {
    padding: 1em;
    overflow: visible;
    position: relative;
    background: white;
    padding-left: 2em;
    height: 100%;
    padding-bottom: 2em;
    padding-right: 2em;
    box-shadow: 0px 2px 3px $shadow-color;
    border-radius: 5px;
    .head {
      display: flex;
      justify-content: space-between;
    }
    .picker-container {
      display: flex;
      background: white;
      .range-date-option {
        display: flex;
        flex-direction: column;
        border-right: 1px solid $shadow-color;
        padding-right: 1em;
        margin-right: 1em;
        button {
          cursor: pointer;
          background: white;
          border: none;
          border-bottom: 1px solid $third-text-color;
          text-align: left;
          padding: 0.5em 0;
          font-size: 1rem;
          outline: none;
          min-width: 7em;

          &:last-child {
            background: $success-background;
            border: none;
            border-radius: 5px;
            text-align: center;
            color: white;
            margin-top: 0.5em;
          }
        }
      }
      .calendar {
        display: flex;
        div .react-datepicker:first-child {
          margin-right: 1em;
        }
      }
    }
  }

  // justify-content:space-between;
}

.sales-turnover {
  width: 18em;
  display: flex;
  flex-direction: column;
  padding: 1em;
  .card-header {
    p {
      color: $secondary-text-color;
      // font-weight: bold;
    }
    margin-bottom: 0.5em;
  }
  .more-info {
    display: flex;
    img {
      width: 5em;
      height: 5em;
    }
    .sold-value {
      font-size: 2.25rem;
      font-weight: 600;
      margin-bottom: 0.25em;
    }

    .mini-info {
      font-size: 0.85rem;
    }
  }
}

.text-red {
  color: red;
  font-weight: 600;
}

.greenbar {
  display: flex;
  flex: 1;
  background: $success-background;
  padding: 0.85em;
  font-weight: 600;
  font-size: 0.85rem;
  color: white;
  margin-bottom: 2em;
  border-radius: 2px;
}

.alert {
  background: #FFE7BD;
  border-radius:3px;
  margin-bottom:2em;
  padding: .5em 1em;
  text-align:center;
  border:1px solid darken(#FFE7BD,10%);
  color:darken(#FFE7BD,65%);
}
